.app__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: black;
  padding: 1rem 1rem;
  height: 130px;
  z-index: 9999;
}
navbar {
  width: 100%;
}
.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.app__navbar-logo img {
  width: 290px;
  height: 290px;
  cursor: pointer;
  z-index: 10000;
}
.app__navbar-logo img:hover {
  scale: 1.1;
  transition: ease-in-out 0.5s;
}
.app__navbar-links {
  flex: 0.8;
  display: flex;

  align-items: center;
  list-style: none;
  z-index: 10000;
}
.app__navabar-lunch-dropdown {
  display: block;
  list-style: none;
  position: absolute;
  right: 10px;
  left: 0px;
  top: 102%;
  border-radius: 6px;
  width: 120px !important;
  box-shadow: 0 8px 32px 0 rgba(252, 251, 251, 0.37);
  z-index: 10000;
  background-color: black;
  border: 1px solid #ff8303 !important;
  color: #ff8303 !important;
}
.app__navabar-lunch-dropdown div {
  padding: 8px;
  color: #ff8303 !important;
}
.app__navabar-lunch-dropdown-none {
  display: none;
}
.app__navbar-links li {
  position: relative;
  margin: 0 0.8rem;
  background-color: #ff8303;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  color: #ff8303;
  padding: 10px 15px;
  transition: 0.5s;
  font-size: 0.8rem;
  width: auto;
}
.app__navbar-links li:hover {
  letter-spacing: 2px;
  background-color: var(--color-golden);
  box-shadow: 0 0 35px var(--color-golden);
  padding: 10px 20px;
  color: white;
}
.app__navbar-links li::before {
  content: "";
  position: absolute;
  inset: 2px;
  background-color: black;
}
.app__navbar-links span {
  position: relative;
  z-index: 1;
}
.app__navbar-login {
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 10000;
}
.app__navbar-order {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.app__navbar-order p {
  color: white;
  font-family: "Rundeck", sans-serif;
}

.app__navbar-name {
  display: none;
  margin: 0 1rem;
  color: #f0e3ca !important ;
  font-family: "Norwester", sans-serif;
}
.app__navbar-smallscreen {
  display: none;
}
.app__navbar-smallscreen_overlay {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 80%;
  height: 100%;
  background-color: black;
  transition: 0.5s step-end;
  flex-direction: column;
  z-index: 5;
}
.app__navbar-smallscreen_overlay .overlay__close {
  font-size: 27px;
  color: var(--color-golden);
  cursor: pointer;

  position: absolute;
  top: 20px;
  right: 20px;
}
.app__navbar-smallscreen-links {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
.slide-bottom {
  background-color: black;
  border: 3px #ff8303 solid;
}

.app__navbar-smallscreen-links li {
  list-style: none;
}

.app__navbar-carticon {
  margin-right: 50px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  color: #ff8303;
  cursor: pointer;
}
.app__navbar_language{

  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  padding: 10px 10px 10px 10px;
  gap: 10px;
  font-family: "Poppins", sans-serif;

  border-radius: 10%;
  border: 2px solid #ff8303
}
.app_navbar_language_circle{
  display: flex;
  align-items: center;
  border-radius: 50%;
 padding:2px;
  border: 2px solid #ff8303;
}

.app__navbar_language a{
 text-decoration: none;
 color: var(--color-theme);
}

@media screen and (max-width: 1080px) {
  .app__navbar-logo {
    display: none;
  }
  .app__navbar-login {
    display: none;
  }
  .app__navbar-order {
    display: none;
  }
  .nav__list {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }

  .nav__link {
    color: #ff8303 !important;
    color: var(--text-color-dark);
    display: inline-flex;
    align-items: center;
    column-gap: 1rem;
    font-size: var(--small-font-size);
    font-weight: 500;
    transition: 0.3s;
  }

  .app__navbar-logo-smallscreen img {
    width: 250px;
    object-fit: cover;
    margin-top: 20px;
  }
  .app__navbar-links {
    display: none;
  }
  .app__navbar-smallscreen {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
  }
  .slide-bottom {
    left: 0px !important;
    z-index: 1000;
    right: 50px;
  }
  .app__footer-break {
    margin: 40px 0;
    border: 1px solid white !important;
  }

  .app__navbar-login-smallscreen {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .app__navbar-order-smallscreen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .app__navbar-smallscreen_overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  .app__navbar-order-smallscreen p {
    color: white;
    font-family: "Rundeck";
    size: 12px;
  }
  .app_navbar-scooter {
    margin-right: 10px;
    height: 70px;
    width: 70px;
  }
  .overlay__close {
    height: 30px;
    width: 30px;
  }
}
@media screen and (max-width: 1344px) {
  .app__navbar-logo img {
    width: 120px;
    height: 120px;
    cursor: pointer;
  }
  .app__navbar-links li {
    margin: 0rem 1rem;
    padding: 5px 15px;
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 1250px) {
  .app__navbar-links li {
    margin: 0 0.7rem;
    padding: 5px 10px;
    font-size: 0.6rem;
  }
}
@media screen and (max-width: 653px) {
  .app__navbar {
    height: 100px;
    padding: 0.5rem;
    height: 68px;
  }
  .app__navbar-logo-smallscreen img {
    height: 130px;
    width: 130px;
    margin-bottom: 10px;
  }
  .app__navbar-login {
    display: none;
  }
  .app__navbar-login a {
    font-size: 10px;
    margin: 0 0.5rem;
    text-align: center;
  }
  .app__navbar-name {
    display: flex;
    margin-top: 1rem;
  }
  .small__navbar {
    font-size: 8px;
  }
  .app__navbar-order-smallscreen {
    display: flex;
    padding-right: 20px;
  }
  .app__navbar-order-smallscreen p {
    font-size: 9px !important ;
  }
  .app_navbar-scooter {
    height: 40px;
    width: 40px;
  }
  .overlay__close {
    height: 20px;
    width: 20px;
  }
}
