.app__intro{
    display: flex;
    
   width: 50%;
   height:330px;
    
    
    
}




.app__logos {
    display: flex;
    border: 3px solid rgb(190, 115, 16);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
   width: 100%;
    
}

.app__logos a {
    cursor: pointer !important;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.app__logos img{
   
   width: 100%;
  
}


@media screen and (max-width: 1400px){
    .app__intro{
        
        height: 200px !important;
    }
    
    
}
@media screen and (max-width: 600px){
    
    .app__logos img{
        height: 100%;
    }
    .app__logos img{
   
        height: 90px;
        width: 120px;
       
     }
}
@media screen and (max-width:400px){
.app__intro {
    width: 100%;
}
}