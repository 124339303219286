.app__footer-links{
    display: flex !important;
    justify-content:space-between;
    
    flex-direction: column;
    gap: 40px;
   
}




.app__footer-information{
    display: flex;
    align-items: center ;
    gap: 20px;
}
.app__footer-information h1,.app__footer-links h1 {
    color: white !important;
    font-size: 25px !important;
    font-family: var(--font-base);
}
.app__footer-information p{
    color: grey;
    font-family: "Poppins", sans-serif;
}
.app__footer-br{
    color: grey;
    border: 1px solid grey;
    margin-bottom: 30px;
}
.app__footer-timetable{
  display: flex;
  justify-content: space-evenly;
  align-items: center;

}

.app__footer-timetable p{
  font-size: 2rem;
  margin-left: 10px;
  color: white;
}
.app__footer_contact-epa p{
  color: white;
}

@media screen {
    
}



.footer {
    position: relative;
    width: 100%;
    top: 400px;
    background: #FF6900;
    z-index: 999;
    min-height: 100px;
      padding: 3% 5%;
      text-align: center;
      color: #fff;
      font-family: "Poppins", sans-serif;
  }
  
  .logo a{
      font-size: 30px;
      color: #fff;
      font-weight: 600;
      margin: 20px 0;
  }
  .logo img {
    height: 150px;
  }
  
  .wave {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 120px;
    background: url("../../assets/svg\ \(2\).png");
    background-size: 1000px 100px;
  }
  
  .wave#wave1 {
    z-index: 1000;
    opacity: 1;
    bottom: 0;
    animation: animateWaves 4s linear infinite;
  }
  
  .wave#wave2 {
    z-index: 999;
    opacity: 0.5;
    bottom: 10px;
    animation: animate 4s linear infinite !important;
  }
  
  .wave#wave3 {
    z-index: 1000;
    opacity: 0.2;
    bottom: 15px;
    animation: animateWaves 3s linear infinite;
  }
  
  .wave#wave4 {
    z-index: 999;
    opacity: 0.7;
    bottom: 20px;
    animation: animate 3s linear infinite;
  }
  .row{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      text-align: start;
      margin-bottom: 20px;
  }
  .row .col {
      width: 25%;
  }
  .row .col ul{
      width: 80%;
      
  }
  .row .col ul li{
    font-family: 'poppins';
    list-style-type: none;
}
  .social-li{
      display: flex;
      gap: 20px;
  }
 
  .app_footer-aukioloajat{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    text-align: center;

  }
  
  .app__footer-time{
    margin: 20px;
  }
  @keyframes animateWaves {
    0% {
      background-position-x: 1000px;
    }
    100% {
      background-positon-x: 0px;
    }
  }
  
  @keyframes animate {
    0% {
      background-position-x: -1000px;
    }
    100% {
      background-positon-x: 0px;
    }
  }
@media screen and (max-width: 1056px){
.row{
    flex-direction: column;
}
.row .col {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
}
.weekdays-ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    
}
.app__footer-break{
    margin: 40px 0;border: 1px solid white !important;;
}
.col .social-li{
    align-items: center;
    justify-content: center;

}
.logo{
    display: flex;
    justify-content: center;
    align-items: center;
}
.app__footer-title{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
}
@media screen and (max-width: 1400px){
.app__footer-timetable p{
  font-size: 25px;
}
.weekdays-ul{
  padding-top: 20px;
}
}