@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
.app__lunch-carditem {
  background-image: url("../../assets/bg.png");
  box-shadow: 0 4px 32px 0 rgba(252, 251, 251, 0.2);
  padding: 20px;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.app__lunch-carditem h3 {
  color: white;
}
.app__lunch-carditem h3 span {
  color: red;
}
.app__lunch_price {
  padding: 10px 0px;
  color: #76885b !important;
}
.app__lunch-carditem p {
  font-family: "Nunito", sans-serif;
  color: white;
  padding: 10px 0px;
}
.carditem_details_seperator {
  width: 90%;
}
.carditem_addtocart {
  cursor: pointer;
  margin: auto;
  text-align: center;
  padding: 1.5rem 0rem;
  width: 8%;
  transition: all ease-in-out;
}
.carditem_addtocart:hover {
  transform: scale(1.2);
}
@media screen and (max-width: 700px) {
  .app__lunch-carditem h3 {
    font-size: 15px;
  }
}
