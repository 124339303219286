.app__navbar_smallscreen_lower_options{
    display: none;
    position: fixed;
    align-items: center;
    bottom:0px;
    justify-content: space-evenly;
    background-color: var(--color-theme);
    color: white;
    width: 100%;
    z-index: 1000000000;
    height: 70px;
    padding: 5px;
    border-radius: 4px;
    overflow-x: hidden;
}
.app_navbar_smallscreen-lower-icons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;;
    font-size: 15px;
    font-family: var(--font-poppins);
}
.app_navbar_smallscreen-lower-icons a {
    font-size: 12px;
}
.vl {
    border-left: 3px solid white;
    height: 60px;
}
.app_navbar_smallscreen_break{
    border: 3px 80px 3px 80px ;
    border :solid white;
}
.app__cart-menuItem{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
}
@media screen and (max-width: 900px){
    .app__navbar_smallscreen_lower_options{
        display: flex;
    }
}