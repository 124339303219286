.app__header {
  width: 50%;
}
.app__header-image1 {
  display: none;
  position: absolute;
  top: 200px;
  left: 50px;
}

.app__header-image1 img,
.app__header-image2 img {
  height: 150px;
}
.app__header-image2 {
  display: none;
  position: absolute;
  top: 200px;
  right: 50px;
}
.app__header-h1 {
  font-family: var(--font-base);
  color: var(--color-golden);
  letter-spacing: 0.04em;
  text-transform: uppercase;
  line-height: 90px;
  font-size: 4rem !important ;
}
.app__header-cooking {
  display: none;
  padding: 20px 40px;
}

@media screen and (max-width: 900px) {
  .app__header-image2 {
    display: flex;
  }
  .app__header-image1 {
    display: flex;
  }
  .app__header {
    width: 100%;
  }
  .app__header-cooking {
    display: flex;
    width: 100%;
  }
  .app__header-cooking img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .app__header-h1 {
    font-size: 50px;
    line-height: 70px;
  }

  .app__header-cooking {
    background-image: url("../../assets/bg.png");
  }
  .app__header-cooking img {
    padding: 50px 50px 0 50px;
  }
}
@media screen and (max-width: 550px) {
  .app__header {
    width: 100%;
  }
  .app__header-image1 img,
  .app__header-image2 img {
    height: 100px;
  }
  .app__header-image1,
  .app__header-image2 {
    /* top: 100px; */
    top: 143px;
  }
  .app__header-image1 {
    left: 30px;
  }
  .app__header-image2 {
    right: 30px;
  }
  .app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-golden);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    line-height: 30px;
    font-size: 2rem !important ;
  }

  .app__wrapper_info p {
    font-size: 12px !important;
    margin: 0%;
  }
}
@media screen and (max-width: 379px) {
}
