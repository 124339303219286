
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,200,300,600,700,900);







  
  


.cards{
  font-family: "Poppins", sans-serif !important;
  
  padding: 50px 50px;
  
}



.card-title a.btn {
 background-color: white;
  border-radius: 4px;
	box-shadow: 0 2px 0px 0 rgba(255,255,255,0.25);
  color: var(--color-theme);
  display: inline-block;
  padding: 6px 30px 8px;
  position: relative;
  text-decoration: none;
	transition: all 0.1s 0s ease-out;
}

.no-touch a.btn:hover {
  background: lighten(teal,2.5);
  box-shadow: 0px 8px 2px 0 rgba(0, 0, 0, 0.075);
  transform: translateY(-2px);
  transition: all 0.25s 0s ease-out;
}

.no-touch a.btn:active,
a.btn:active {
  background: darken(teal,2.5);
  box-shadow: 0 1px 0px 0 rgba(255,255,255,0.25);
  transform: translate3d(0,1px,0);
  transition: all 0.025s 0s ease-out;
}

div.cards {
  margin: 300px auto 40px auto;
 
  text-align: center;
}

a.card {
  
  display: inline-block;
  margin: 8px;
  border-radius: 10px !important;
  max-width: 300px;
  perspective: 1000;
  position: relative;
  text-align: left;
  transition: all 0.3s 0s ease-in;
  width: 280px !important;
  z-index: 1;
  border: 1px solid var(--color-theme);
  padding: 12px;
  margin: 10px;
  

  img {
    max-width: 250px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  

  div.card-title {
    background: var(--color-theme);
    padding: 14px 15px ;
    position: relative;
    z-index: 0;
    
    
    h2 {
      font-size: 17px;
      font-weight: 600;
      letter-spacing: -0.05em;
      margin: 0;
      padding: 0;
      color: white;
      small {
        display: block;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: -0.025em;
      }
    }
  }

 

  
}


/* For screens smaller than 768px (mobile devices) */
@media screen and (max-width: 600px) {
  .cards {
    
    width: 100% !important;

  }
  

  
}


