.app__cards-h1-gallery{
    
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 70px !important;
}
.app__cards-h1-gallery {
    color: transparent;
    -webkit-text-stroke: 2px rgb(190, 115, 16) ;
    position: relative;
}
.app__cards-h1-gallery::before{
    content: "Galleriamme" !important;   
    position: absolute;
    width: 0%;
    height: 100%!important;
    overflow: hidden;
    color: rgb(190, 115, 16);
    transition: 1s ease-in-out;
}
.app__meals-h1-gallery:hover::before{
    width: 100%;
    filter: drop-shadow(0 0 25px rgb(190, 115, 16) );
}
@media screen and (max-width: 900px){
    .app__cards-h1-gallery{
        font-size: 45px !important ;
    }
}