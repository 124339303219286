.cart__empty-container {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 80vh;
  margin-bottom: -50%;
}
.cart__empty-icon {
  filter: invert(100%);
}
.app__cart_div_1{
  display: flex;
  justify-content: center;
  align-items: center;
}




.cart__empty-back {
  padding: 5px 15px;
  font-weight: 600;
  font-size: 1.2em;
  border: none;
}
.cart__container {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: -12%;
}
.cart {
  width: 96%;
  margin: 0 10px;
  color: white;
  border-radius: 4px;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ff8303;
  padding: 10px;
  font-weight: bold;
}

.product1 {
  width: 30%;
}

.cart-header > div {
  flex: 1;
  text-align: center;
}

.cart-items {
  border: 1px solid #ccc;
}
.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.cart-item > div {
  flex: 1;
  text-align: center;
}

.quantity {
  display: flex;
  justify-content: center;
  align-items: center;
}

.quantity button {
  padding: 5px 10px;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  cursor: pointer;
}

.quantity span {
  margin: 0 10px;
}

.delete button {
  background-color: transparent !important;
  border: none;
  cursor: pointer;
  font-size: 16px;
}
/* this */

.order-summary {
  width: 100%;

  margin: 0 auto;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.order-summary > h2 {
  color: white;
  background-color: #ff8303;
  padding: 5px 10px;
}

.order-item {
  display: flex;
  justify-content: space-between;
  border-bottom: #666;
  padding: 10px;
  background-color: #f8f8f8;
  border-bottom: #666 solid 1px;
}

.order-totals {
  padding: 10px;
  background-color: #f8f8f8;
  /* border-radius: 4px; */
  padding-top: 40px;
}

.summary_total,
.payable-amount,
.alv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-weight: bold;
}

.alv {
  font-size: 0.9em;
  color: #666;
}

/* email */

.cart__email-label {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-top: 0px;
  color: white;
}

.cart__email-input {
  width: 100%;
  padding: 7px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.cart__email-error {
  color: red;
}
.App_cartInput_Button {
  margin-top: 40px;
  width: 100%;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  background-color: #0e2c22 !important;
  cursor: pointer;
  font-weight: bold;
}
.cart__order-button {
  cursor: pointer;
  padding: 5px 15px;
  font-weight: 600;
  font-size: 1.17em;
  margin-right: 10%;
  border: none;
}
.cart__order-button:disabled {
  cursor: default;
  background-color: #935e28 !important;
}
.cart__empty-back {
  padding: 5px 15px;
  font-weight: 600;
  font-size: 1.2em;
  border: none;
}

.quantity > button {
  border: none;
}
.cart__options-buttons {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  gap: 15px;
}
.cart__options-buttons > button {
  width: 100%;
}
.summary__container {
  width: auto;
  margin: 0 10px;
}

@media (min-width: 700px) {
  .cart {
    width: 70%;
    margin-left: 20px;
  }
  .cart__container {
    flex-direction: row;
    width: 100%;
    margin-bottom: -15%;
  }
  .cart__empty-container {
    margin-bottom: -25%;
  }
  .cart__heading {
    font-size: 1.5em;
  }
  .cart__options-buttons {
    justify-content: center;
  }

  .cart__email-input {
    width: 100%;
  }
  .order-summary {
    max-width: 500px;
  }
  .summary__container {
    width: 350px;
  }
}
