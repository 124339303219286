.app_successfull h1 {
    color: #88B04B;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
  }
  .app_successful p {
    color: #1A4D2E;
    font-family: "Poppins", sans-serif;
    font-size:15px;
    margin: 0;
    
  }
  i {
    display: flex;
    justify-content: center;

    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left:-15px;
  }
.checkmark {
  color: #9ABC66;
  font-size: 100px;
  line-height: 200px;
  margin-left:-15px;
}
 .app_successfull_card {
  background: var(--color-theme) ;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #C8D0D8;
  display: inline-block;
  margin: 0 auto;
}
.app_sucessfully_order{
  margin-top: 70px;
  display: flex;
  justify-content: center;
  justify-items: center;
}



@media (min-width: 700px){
  .app_sucessfully_order{
    margin: 0 10px;
  }
}