@import url('https://fonts.googleapis.com/css2?family=Madimi+One&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

.app__menu {
   background-color: black;
}

.app__lunchmenu {
   padding: 100px;
   display: flex;

   place-items: space;
   gap: 50px;
   font-family: "Poppins", sans-serif;

}

.app__lunchmenu-left {
   position: sticky;
   top: 100px;
   height: calc(100vh - 150px);
   overflow-y: auto;
   overflow-x: hidden;
   width: 30%;
   border-radius: 6px;
   background-color: #FFF6E9;
   backdrop-filter: blur(10px);
   box-shadow: 0 8px 32px 0 rgba(252, 251, 251, 0.37);
 }
 
 /* Scrollbar styles */
 .app__lunchmenu-left::-webkit-scrollbar {
   width: 8px; /* Width of the scrollbar */
 }
 
 .app__lunchmenu-left::-webkit-scrollbar-track {
   background-color: #f5f5f5; /* Color of the scrollbar track */
 }
 
 .app__lunchmenu-left::-webkit-scrollbar-thumb {
   background-color: #888; /* Color of the scrollbar thumb */
   border-radius: 4px; /* Rounded corners of the scrollbar thumb */
 }
 
 .app__lunchmenu-left::-webkit-scrollbar-thumb:hover {
   background-color: #555; /* Color of the scrollbar thumb on hover */
 }

.app__lunchmenu-right {
   width: 70%;

}

.app__lunchMenu-items {
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   gap: 20px;
   flex-direction: column;

}

.app__lunchmenu h1 {
   color: white;
   background-color: #BE3144;
   padding: 20px;

}

.app__lunchmenu-buttons {
   display: flex;
   position: relative;
   align-items: flex-start;
   justify-content: space-between;
   flex-direction: column;
   padding: 30px;
   z-index: 1;


}

.app__lunchmenu-button {
   width: 100%;
   color: white;
   background-color: #BE3144;
   height: 60px;
   display: flex;
   align-items: center;
   padding-left: 30px;
   border-bottom: 1px solid white;
   border-radius: 3px;
}

.app__lunch-image1 {
   position: absolute;
   top: -10%;
   right: -7%;
   z-index: -1;
}

.app__lunch-image2 {
   position: absolute;
   top: 1000px;
   right: 1%;
   z-index: 10000;

}

.app__lunch-image2 img {
   height: 1000px;
   background-color: whitesmoke;
}

.app__lunch-image1 img {

   height: 100px;
}

.clicked-button {
   background-color: #872341;
}

.app__lunchmenu-right h1 {
   font-family: "Montserrat", sans-serif;
   color: white;
   background-color: #3A4D39;
}

.app__lunchmenu-left-smallscreen {
   display: none;
   height: 60px;
   width: 100%;

   align-items: center;
   justify-content: space-between;
   padding: 10px;
   overflow-x: scroll;
   overflow-y: hidden;
   gap: 5px;
   font-size: 12px;
   background-color: #12372A;
   font-family: "Poppins", sans-serif;



}

.app__lunchmenu-front-buttons {
   display: flex;
   align-items: center;
   justify-content: space-between;
   z-index: 101;
   gap: 20px;
   position: relative;
   top: 100px;
   left: 40%;
}

.clicked-button-smallscreen {
   color: white !important;
   background-color: #3A4D39 !important;
}

.app__lunchmenu-left-smallscreen div {
   margin: 10px 5px;
   height: 30px;
   max-width: max-content;
   border-radius: 3px;
   padding: 0px 10px;
   background: transparent;
   border: 1px solid #3A4D39;
   color: #FFF6E9;


}

.app__lunchmenu-frontphoto {
   height: 400px;
   overflow: hidden;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   object-fit: contain;

   opacity: 0.8;
}

.app__lunchmenu-frontphoto img {
   width: 100%;
   position: absolute;
   top: 0%;
   z-index: 98;
   object-fit: cover;
   height: 60%;

}

.APP_LUNCHMENU-H1 {
   color: white !important;
   font-family: "Madimi One", sans-serif;
   bottom: 50px;
   font-size: 30px;
   background-color: #12372A;
   padding: 20px;
   border-radius: 3px;
   box-shadow: 2px 3px 25px rgba(246, 245, 245, 0.3);
   position: relative;

}

.app_lunchmenu_buffet_front {
   z-index: 100;
   display: flex;
   align-items: center;

   flex-direction: column;
}

.lunch-time-buffet {
   color: white !important;
   background-color: transparent !important;
   color: white;
   padding: 15px;
   text-transform: uppercase;
   font-family: "Madimi One", sans-serif;
   border-radius: 3px;
}

.fixed_lunchtime_time {
   position: fixed;
   top: 100px;
   left: 0px;

}

.lunch-time-buffet-available {
   background-color: #3A4D39;
   color: white;
   padding: 15px;
   text-transform: uppercase;
   font-family: "Madimi One", sans-serif;
   border-radius: 3px;

}

@media screen and (max-width:1300px) {
   .app__lunchmenu-left {
      width: 32%;
      height: calc(110vh - 150px);
   }

   .app__lunchmenu-right {
      float: 68%;
   }

   .app__lunchmenu-frontphoto img {
      top: 0px;
      height: 550px;
      z-index: -100000;
   }

}

@media screen and (max-width: 700px) {
   .app__lunchmenu-left-smallscreen {
      display: flex;
   }

   .app__lunchmenu-left {
      display: none;
   }

   .app__lunchmenu {
      flex-direction: column;
      padding: 40px 20px;
      /* Reduced padding */
   }

   .APP_LUNCHMENU-H1 {
      font-size: 15px;
   }

   .app__lunchmenu-right {
      width: 100%;
   }

   .app__lunchmenu-right h1 {
      font-size: 23px;
   }

   .app__lunchmenu h1 {
      padding: 10px;
   }

   .app__lunchmenu-frontphoto img {
      top: 9%;
      height: 350px;
   }

   .custom__button {
      font-size: 14px;
      padding: 0.3rem 1rem;
   }

   .app__lunchmenu-front-buttons {
      top: 50px;
      /* Adjusted position */
      left: 25%;
      /* Adjusted position */
   }

   .app_lunchmenu_buffet_front {
      padding: 0 20px;
      /* Added padding */
   }
}