.container-find{
    width: 80%;
    margin: 50px auto;
    font-family: "Poppins", sans-serif;
}
h1{
    color: wheat;
    margin-bottom: 40px;
    font-family: "Poppins", sans-serif;
}
.container-find  p{
   margin-bottom: 10px;
   color: wheat;
   font-family: "Poppins", sans-serif;
}
.contact-box{
    background-color: #fff;
    display: flex;
    
}
.contact-left{
    flex-basis: 60%;
    padding: 40px 60px;
}
.contact-right{
    flex-basis: 40%;
    padding: 40px 60px;
    background-color: var(--color-theme);
    color: white;
}
.input-row{
    
    display: grid; 
    grid-template-columns: repeat(2, 1fr); 
    grid-column-gap: 10px; 
    margin: 10px 0; 
    
    
}
.input-row .input-group{
   height: 100px;
}

input{
    width: 100%;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
    font-family: "Poppins", sans-serif;
}
textarea{
    width: 100%;
    border: 1px solid #ccc;
    outline: none;
    padding: 10px;
}
button{
    font-family: "Poppins", sans-serif !important;
    color: white !important;
    background-color: var(--color-theme) !important;
}
.aboutus{
    
    padding: 20px;;
   
    box-shadow: 0px 0px 5px 2px white;
}
.app__contact-information p{
    gap: 0px !important;
}
@media screen and (max-width: 1056px){
    .contact-right{
        display: none;
    }
    .input-row{
        grid-template-columns: repeat(1, 1fr); 
        
    }
    .contact-left{
        flex-basis: 100%;
        
    }
   
}