
#image-track > .image {
    width: 40vh; height: 40vh;
    object-fit: cover;
    object-position: center;
    margin: 10px;
   height: 500px;
   
}
#image-track {
    display: flex;
    gap: 4vimn;
    
    position : absolute ;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(0%,-50%);
}
.app__header-cooking{
    display: none;
}
@media screen and (max-width:900px){
    #image-track {
        display: none;
    }
.app__header-cooking{
    display: flex;
    width: 100%;
    
}



}
@media screen and (max-width:1400px) and (min-width:900px) {
    #image-track{
        left: 60%;
    }
}
@media screen and (min-width:1400px){
    #image-track .image {
        height: 500px;
    }
    


}
@media screen and (max-width:550px){
    
}