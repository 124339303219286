/* App.css */
.App_CartInput {
    display: flex;
    margin: 50px;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    width: 90%;
    background-color: #0e2c22;
    margin-top: 5%;
  }
  /* TableBookingForm.css */
  .table-booking {
    font-family: "Poppins", sans-serif;
    color: #fff;
    padding: 20px;
    border-radius: 5px;
    max-width: 900px;
    width: 100%;
  }
  
  .table-booking h2 {
    margin-top: 0;
    font-size: 32px;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 30px;
    color: #ff8303; /* Golden yellow color */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .table-booking h2::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 120px;
    height: 5px;
    background-color: #ff8303; /* Golden yellow color */
    box-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .form-group {
    flex: 1;
    margin-right: 10px;
  }
  
  .form-group:last-child {
    margin-right: 0;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 8px;
    border-radius: 3px;
    border: none;
  }
  
  button[type="submit"] {
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    margin-top: 5px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .error {
    color: red;
    margin-top: 5px;
  }
  .table__booking-sideimg {
    display: block;
  }
  @media (max-width: 600px) {
    .form-row {
      flex-direction: column;
      margin-bottom: 0px;
    }
  
    .form-group {
      margin-right: 0;
      margin-bottom: 15px;
    }
    button[type="submit"] {
      margin-top: auto;
    }
  }
  @media (max-width: 770px) {
    .table__booking-sideimg {
      display: none;
    }
  }
  