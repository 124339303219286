.app__lunchtime {
    height: 60px;
    width: 150px;
    background: #FF8303;
    position: fixed;
    top: 230px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    font-family: "Poppins", sans-serif;
    gap: 5px;
    flex-direction: column;
    margin: 0;
    padding: 5px;
}
.app__specialtime{
    height: 60px;
    width: 150px;
    background: #2C7865;
    position: fixed;
    top: 330px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    font-family: "Poppins", sans-serif;
    gap: 5px;
    flex-direction: column;
    margin: 0;
    padding: 5px;
}
.app__lunchtime h2 {
    color: white;
    font-size: 15px;
}
.app__specialtime h2 {
    color: white;
    font-size: 15px;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    /* For mobile devices */
    .app__specialtime {
        width: 70px;
        height: 35px;
        font-size: 7px;
    }
    .app__specialtime h2 {
        font-size: 9px;
    }
    .app__lunchtime {
        width: 70px;
        height: 35px;
        font-size: 8px;
    }
    .app__lunchtime h2 {
        font-size: 8px;
    }

    
}

  