.event__notice-lg {
  font-family: "Poppins", sans-serif;
  position: fixed;
  bottom: 4%;
  width: 100%;
  background-color: #3a4d39cb;
  
  color: #ff8103;
  padding: 3px 0;
  text-align: center;
  display: block;
  z-index: 9999;
}
.fixed-cls {
  position: fixed !important;
}
.event__notice-sm {
  font-family: "Poppins", sans-serif;
  position: sticky;
  color: white;
  display: none;
  background: #0e2c22;
  height: 70px;
  width: 100%;
  top: 0px;
  z-index: 100;
}
.top_adjust {
  top: 75px;
}

.marquee {
  /* animation properties */
  /* -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 12s linear infinite;
  -webkit-animation: my-animation 12s linear infinite;
  animation: my-animation 12s linear infinite; */
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(80%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-80%);
  }
}

@media (max-width: 700px) {
  .event__notice-lg {
    display: none;
  }
  .event__notice-sm {
    display: flex;
    height: 130px;
    align-items: center;
    color: #ff8103;
    width: 100%;
  }
}
