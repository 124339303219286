.app__specialmenu-items {
  padding-top: 20px;
}
.app__lunchmenu-left-smallscreen {
  display: none;
  height: 60px;
  width: 100%;

  align-items: center;
  justify-content: space-between;
  padding: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 5px;
  font-size: 12px;
  background-color: #12372a;
  font-family: "Poppins", sans-serif;
}
.clicked-button-smallscreen {
  color: white !important;
  background-color: #3a4d39 !important;
}
.app__lunchmenu-left-smallscreen a {
  display: inline-flex;
  max-inline-size: fit-content;
  margin: 10px 5px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 0 10px;
  background: transparent;
  border: 1px solid whitesmoke !important;
  color: #fff6e9;
  white-space: nowrap;
}
.app__specialmenu_akhanda {
  position: relative;
  top: 100px;
  right: 550px;
  z-index: 8000;
}
.APP_LUNCHMENU-H1-speciamenu {
  padding: 20px;
  color: white !important;
  font-family: var(--font-base);
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 800;
}

.fixed-class {
  position: fixed !important;
  top: 75px;
  /* top:0px; */
}
.div-break {
  height: 100px;
}
@media screen and (max-width: 1300px) {
  .app__lunchmenu-left {
    width: 32%;
  }
  .app__lunchmenu-right {
    width: 65%;
  }
  .app__specialmenu_akhanda {
    right: 18%;
  }
}
@media screen and (max-width: 700px) {
  .app__lunchmenu-left-smallscreen {
    display: flex;
  }
  .app__lunchmenu-left {
    display: none;
  }
  .custom__button {
    font-size: 14px;
    padding: 0.3rem 1rem;
  }
  .APP_LUNCHMENU-H1-speciamenu {
    font-size: 24px;
    padding: 10px;
  }
  .app__specialmenu_akhanda {
    top: 10%;
    left: 0%;
    transform: translate(-50%, -50%);
    width: 90%;
  }
  .app__lunchmenu-front-buttons {
    top: 50px; /* Adjusted position */
    left: 10%; /* Adjusted position */
  }
}
