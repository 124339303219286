.cart-icon {
  position: relative;
}

.cart-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 0.8em;
}
.fa {
  width: 20px;
  height: 32px;
}

@media screen and (max-width: 600px) {
  .fa-shopping-cart {
    size: 50px;
  }
  

  
}
