.app__cart-price-rolling{
    
    
   
    border-block: 1px solid #0e2c22;
    position: fixed;
    bottom: 0.5%;
    left: 0px;
    background-color: #0e2c22;
    color: var(--color-theme);
    display: flex;
    --gap: 360px;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap);
    font-family: "Poppins", sans-serif;
  font-weight: 600;
    overflow: hidden;
    user-select: none;
    width: 100%;
    z-index: 1000000;
    height: 50px;
}
.app__cart-price-rolling h1{
    margin-top: 2%;
    font-size: 20px;
    min-width: 30%;
    gap: 1rem;
    animation: scroll 10s linear infinite;


}

@keyframes scroll {
    0% {
        transform: translateX(0 );

    }
    100%{
        transform: translateX(-90%);
    }
}
@media (max-width: 1500px){
    .app__cart-price-rolling h1{
        font-size: 12px;
        height: 10px !important;
        margin-top: 3%;
        min-width: 65%;

    }
}
@media (max-width: 700px){
    .app__cart-price-rolling{

        font-size: 15px;
        bottom: 8% !important;

        gap: 1px !important;
    }
    .app__cart-price-rolling h1{
        font-size: 12px;
        height: 30px;
        margin-top: 10%;
        min-width: 65%;

    }
}